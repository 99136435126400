import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import HealthSurvey from "../../components/Epharmacy/HealthSurvey"

export default () => (
  <Layout
    // title="Health Survey"
    title={
      <span className="is-size-3">
        To help us serve you better,
        <br className="is-hidden-mobile" /> help us know you better.
      </span>
    }
    process="epharmacy"
    seoTitle="Health Survey"
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <HealthSurvey />
    </Container>
  </Layout>
)
